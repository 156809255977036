import { createBrowserRouter } from 'react-router-dom';


export const router = createBrowserRouter([
  {
    path: "/",
    async lazy() {
      const Layout  = await import('./pages/main/index');

      return { Component: Layout.default }
    },
  },
  {
    path: "/login",
    async lazy() {
      const Layout  = await import('./pages/login');

      return { Component: Layout.default }
    }
  },
]);